<template>
  <el-container class="main-container">
    <el-card>
      <el-row :gutter="10">
        <el-col :span="4" :offset="0">
          <el-input
            v-model="queryInfo.query"
            placeholder="请输入内容"
            clearable
            @clear="query"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="query"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <ele-select v-model="queryInfo.state"
            placeholder="状态筛选"
            :options="dataStateList"
            @change="query"></ele-select>
        </el-col>
      </el-row>
      <ele-table :columns-desc="columns"
        :is-show-index="true"
        :request-fn="getSystemUsers"
        :right-buttons="rightButtons"
        :is-show-top-delete="false"
        :is-show-right-delete="false"
        ref="table"></ele-table>
    </el-card>
  </el-container>
</template>

<script>
import{ tableDataStateList, genderList } from '../../assets/js/constant'
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        state: ''
      },
      dataStateList: tableDataStateList,
      columns: {
        userId: {
          text: '用户 Id'
        },
        name: {
          text: '用户名'
        },
        gender: {
          text: '性别',
          type: 'status',
          options: genderList
        },
        avatarUrl: {
          text: '头像',
          type: 'image'
        },
        dataState: {
          text: '状态',
          type: 'status',
          options: tableDataStateList
        }
      },
      rightButtons: [
        {
          text: '封禁',
          attrs: {
            type: 'warning'
          },
          click: (id, index, data) => {
            this.ban(data)
          }
        },
        {
          text: '恢复',
          attrs: {
            type: 'success'
          },
          click: (id, index, data) => {
            this.restore(data)
          }
        }
      ]
    }
  },
  created() {},
  methods: {
    query() {
      this.$refs.table.getData()
    },
    async getSystemUsers(params) {
      const { data: res } = await this.$api.getAppUsers(Object.assign(params, this.queryInfo))
      if (res.code === 1) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      return res.data
    },
    async ban(data) {
      const confirm = await this.$confirm(
        "此操作将封禁该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }
      );
      if (confirm === "confirm") {
        const { data: res } = await this.$api.banUser(data.userId, false);
        if (res.code > 0) {
          return this.$notify.error({
            title: '错误',
            message: res.message
          })
        }
        this.$notify.success({
          title: '成功',
          message: res.message
        })
        this.query();
      }
    },
    async restore(data) {
      const confirm = await this.$confirm(
        "此操作将恢复该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }
      );
      if (confirm === "confirm") {
        const { data: res } = await this.$api.banUser(data.userId, true);
        if (res.code > 0) {
          return this.$notify.error({
            title: '错误',
            message: res.message
          })
        }
        this.$notify.success({
          title: '成功',
          message: res.message
        })
        this.query();
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
